import {
    type AiOperationsExecution,
    type ConsumptionFilterOptions,
    getAllExecutions,
} from '@/api/aiOperationsExecution.ts'
import { DefaultDataTable } from '@/components/DefaultDataTable.tsx'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { useQuery } from '@tanstack/react-query'
import type { ColumnDef } from '@tanstack/react-table'
import React, { useEffect, useState } from 'react'
import { endOfMonth, startOfMonth, sub, subDays } from 'date-fns'
import { getAllProjects } from '@/api/projects.ts'
import AiriaIcon from '@/assets/icons/airia-icon.svg?react'
import { Link, useNavigate } from '@tanstack/react-router'
import { type paginationAndSortingParams } from '@/components/pagination/paginationHeader.tsx'
import { Button } from '@/components/ui/button.tsx'
import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog.tsx'
import ConsumptionFilters from '@/components/home/consumption/ConsumptionFilters.tsx'
import ExportForm from '@/components/home/consumption/ExportForm.tsx'
import { Copy } from 'lucide-react'
import { toast } from '@/components/ui/use-toast.ts'
import { formatCurrency } from '@/lib/utils.ts'
import ProviderIcon from '@/components/modelLibrary/ProviderIcon.tsx'
import { usePagination } from '@/hooks/use-pagination.ts'
import { useSorting } from '@/hooks/use-sorting.ts'

interface ConsumptionProps {
    queryParams: paginationAndSortingParams & {
        startTime?: number
        endTime?: number
        projectIds?: string
    }
}

const Consumption: React.FC<ConsumptionProps> = ({ queryParams }) => {
    const [executionOperations, setExecutionOperations] = useState<AiOperationsExecution[]>([])
    const [consumptionFilter, setConsumptionFilter] = useState<ConsumptionFilterOptions>({})
    const [dialogOpen, setDialogOpen] = useState(false)
    const [dialogType, setDialogType] = useState<'export' | 'filter'>('filter')
    const [filterCount, setFilterCount] = useState<number>(0)
    const navigate = useNavigate()
    const { limit, onPaginationChange, paginationState } = usePagination({
        initialPageIndex: queryParams.pageNumber ? queryParams.pageNumber - 1 : 0,
        initialPageSize: queryParams.pageSize,
    })
    const { sortingState, onSortingChange, field, order } = useSorting({
        initialField: queryParams.sortBy ?? 'createdAt',
        initialOrder: queryParams.sortDirection ?? 'DESC',
    })
    const operationsQuery = useQuery({
        queryKey: [QueryKeys.ALL_OPERATIONS_EXECUTIONS, queryParams],
        queryFn: () => getAllExecutions(queryParams),
    })
    const projectsQuery = useQuery({
        queryKey: [QueryKeys.PROJECTS],
        queryFn: () => getAllProjects(),
    })

    useEffect(() => {
        navigate({
            search: {
                ...queryParams,
                pageNumber: paginationState.pageIndex + 1,
                pageSize: limit,
                sortBy: field,
                sortDirection: order,
            },
        })

        setConsumptionFilter({
            projectIds: queryParams.projectIds?.split(','),
            executionDate: determineExecutionDate(),
        })
    }, [paginationState, sortingState, queryParams])

    useEffect(() => {
        if (operationsQuery.data) {
            setExecutionOperations(operationsQuery.data.items)
        }
    }, [operationsQuery.data])

    useEffect(() => {
        setFilterCount(0)
        Object.keys(consumptionFilter).forEach((k) => {
            // do not add default state to filter count
            if (k === 'projectIds' && consumptionFilter.projectIds?.length === 0) {
                return
            } else if (consumptionFilter[k as keyof ConsumptionFilterOptions]) {
                setFilterCount((prev) => prev + 1)
            }
        })
    }, [consumptionFilter])

    const determineExecutionDate = (): 'thisMonth' | 'lastMonth' | 'last90Days' | undefined => {
        if (!queryParams.startTime) return undefined

        const now = new Date()
        const startTime = new Date(queryParams.startTime * 1000)
        const ninetyDaysAgo = subDays(now, 90)
        const startOfCurrentMonth = startOfMonth(now)
        const endOfCurrentMonth = endOfMonth(now)

        if (startTime <= ninetyDaysAgo) {
            return 'last90Days'
        } else if (startTime > endOfCurrentMonth) {
            return 'thisMonth'
        } else if (startTime >= startOfCurrentMonth && startTime <= endOfCurrentMonth) {
            return 'thisMonth'
        } else if (queryParams.endTime) {
            return 'lastMonth'
        }

        return undefined
    }

    const onCopyIconClick = (executionId: string) => {
        navigator.clipboard.writeText(executionId)
        toast({
            title: 'Copied!',
            description: 'Execution ID copied to clipboard.',
        })
    }

    const onOpenDialog = (type: 'export' | 'filter', open: boolean) => {
        setDialogType(type)
        setDialogOpen(open)
    }

    const resetActiveFilters = () => {
        setConsumptionFilter({
            projectIds: undefined,
            executionDate: undefined,
        })
        navigate({
            search: {
                pageSize: 25,
                pageNumber: 1,
                sortDirection: 'DESC',
            },
        })
    }

    const onFilterChange = (filter: ConsumptionFilterOptions) => {
        resetActiveFilters()
        setConsumptionFilter(filter)
        const searchWithProjectIds = onProjectIdFilter(filter.projectIds)
        const searchWithExecutionDate = onExecutionDateFilter(filter.executionDate)
        const search = {
            ...queryParams,
            ...searchWithExecutionDate,
            ...searchWithProjectIds,
        }
        navigate({
            search: { ...search },
        })
    }
    const onProjectIdFilter = (
        projectIds?: string[]
    ): {
        projectIds?: string
    } => {
        if (projectIds && projectIds.length > 0) {
            return {
                projectIds: projectIds.join(','),
            }
        } else {
            return {
                projectIds: undefined,
            }
        }
    }

    const onExecutionDateFilter = (
        executionDateStr?: 'thisMonth' | 'lastMonth' | 'last90Days' | number
    ): {
        startTime?: number
        endTime?: number
    } => {
        if (executionDateStr === 'thisMonth') {
            const monthStart = startOfMonth(new Date())
            return {
                startTime: Math.floor(monthStart.getTime() / 1000),
                endTime: undefined,
            }
        } else if (executionDateStr === 'lastMonth') {
            const now = new Date()
            const lastMonthStart = startOfMonth(sub(now, { months: 1 }))
            const lastMonthEnd = endOfMonth(lastMonthStart)
            return {
                startTime: Math.floor(lastMonthStart.getTime() / 1000),
                endTime: Math.floor(lastMonthEnd.getTime() / 1000),
            }
        } else if (executionDateStr === 'last90Days') {
            const ninetyDaysAgo = sub(new Date(), { days: 90 })
            return {
                startTime: Math.floor(ninetyDaysAgo.getTime() / 1000),
                endTime: undefined,
            }
        } else {
            return { startTime: undefined, endTime: undefined }
        }
    }

    const columns: ColumnDef<AiOperationsExecution>[] = [
        {
            accessorKey: 'executionDateTime',
            header: 'Timestamp',
            cell: ({ row }) => {
                const date = new Date(row.original.executionDateTime)
                return (
                    <span
                        className={
                            'whitespace-nowrap overflow-hidden max-w-[120px] text-ellipsis inline-block'
                        }
                    >{`${date.toLocaleDateString(undefined, { dateStyle: 'short' })} at ${date.toLocaleTimeString(undefined, { timeStyle: 'short' })}`}</span>
                )
            },
        },
        {
            accessorKey: 'providerType',
            header: 'Provider',
            cell: ({ row }) => (
                <div className={'flex gap-1 items-center'}>
                    <ProviderIcon
                        provider={row.original.providerType}
                        className="w-[17px] h-[17px]"
                    />
                    {row.original.providerType}
                </div>
            ),
        },
        {
            accessorKey: 'modelName',
            header: 'Model Name',
            cell: ({ row }) => (
                <span
                    className={
                        'flex items-center whitespace-nowrap overflow-hidden max-w-[200px] text-ellipsis inline-block'
                    }
                >
                    {row.original.modelName}
                </span>
            ),
        },
        {
            accessorKey: 'executionSourceType',
            header: 'Source',
        },
        {
            accessorKey: 'pipelineId',
            header: 'Pipeline',
            cell: ({ row }) => (
                <Link
                    to={'/$projectId/pipelines/$pipelineId/$versionNumber'}
                    params={{
                        projectId: row.original.projectId,
                        pipelineId: row.original.pipelineId,
                        versionNumber: row.original.pipelineVersion,
                    }}
                    className={
                        'flex items-center text-primary hover:underline whitespace-nowrap overflow-hidden max-w-[200px] text-ellipsis inline-block'
                    }
                >
                    {row.original.pipelineName !== ''
                        ? row.original.pipelineName
                        : row.original.pipelineId}
                </Link>
            ),
        },
        {
            accessorKey: 'projectId',
            header: 'Project',
            cell: ({ row }) => {
                const project = projectsQuery.data?.find(
                    (project) => project.id === row.original.projectId
                )
                return (
                    <div className={'flex gap-1 items-center'}>
                        {project?.projectIcon ? (
                            <img
                                src={project.projectIcon}
                                alt={project.name}
                                height={17}
                                width={17}
                            />
                        ) : (
                            <AiriaIcon className="w-[17px] h-[17px]" />
                        )}
                        <span
                            className={
                                ' whitespace-nowrap overflow-hidden max-w-[200px] text-ellipsis inline-block'
                            }
                        >
                            {project?.name}
                        </span>
                    </div>
                )
            },
        },
        {
            header: 'Total Spend',
            accessorKey: 'totalTokenAmountConsumed',
            cell: ({ row }) => {
                return <span>{`$${row.original.totalTokenAmountConsumed}`}</span>
            },
        },
        {
            accessorKey: 'inputTokenAmountConsumed',
            header: 'Input Spend',
            cell: ({ row }) => {
                return <span>{`$${row.original.inputTokenAmountConsumed}`}</span>
            },
        },
        {
            accessorKey: 'outputTokenAmountConsumed',
            header: 'Output Spend',
            cell: ({ row }) => {
                return <span>{`$${row.original.inputTokenAmountConsumed}`}</span>
            },
        },
        {
            header: 'Total Tokens',
            accessorKey: 'totalTokenCountConsumed',
        },
        {
            accessorKey: 'inputTokenCountConsumed',
            header: 'Input Tokens',
        },
        {
            accessorKey: 'outputTokenCountConsumed',
            header: 'Output Tokens',
        },
        {
            accessorKey: 'balanceUsed',
            header: 'Balanced Used',
            cell: ({ row }) => {
                const displayedBalance = +row.original.balanceUsed
                return (
                    <span>
                        {isNaN(displayedBalance)
                            ? `$${row.original.balanceUsed}`
                            : formatCurrency(displayedBalance)}
                    </span>
                )
            },
        },
        {
            accessorKey: 'executionId',
            header: 'Execution ID',
            cell: ({ row }) => (
                <span className="whitespace-nowrap overflow-hidden max-w-[200px] text-ellipsis inline-block">
                    <Button
                        onClick={() => onCopyIconClick(row.original.executionId)}
                        variant={'ghost'}
                    >
                        <Copy className={'h-4 w-4'} />
                    </Button>
                </span>
            ),
        },
    ]

    return (
        <div className={'flex flex-col gap-2 mt-6'}>
            <div className="flex flex-row justify-end items-center">
                <div className={'flex gap-4'}>
                    <Button
                        onClick={() => onOpenDialog('filter', true)}
                        variant={filterCount > 0 ? 'default' : 'secondary'}
                        className="flex items-center gap-1"
                    >
                        Filters
                        {filterCount > 0 && (
                            <span
                                className={
                                    'h-5 w-5 flex justify-center bg-primary-foreground rounded-full text-primary px-2'
                                }
                            >
                                {filterCount}
                            </span>
                        )}
                    </Button>
                    <Button
                        onClick={() => {
                            onOpenDialog('export', true)
                        }}
                        type="button"
                        variant="secondary"
                    >
                        Export
                    </Button>
                </div>
            </div>
            <DefaultDataTable
                pagination
                columns={columns}
                data={executionOperations}
                serverPagination
                totalCount={operationsQuery.data?.totalCount ?? 0}
                limit={limit}
                onPaginationChange={onPaginationChange}
                onSortingChange={onSortingChange}
                paginationState={paginationState}
                sortingState={sortingState}
                rowsPerPage={queryParams.pageSize}
            />
            <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
                <DialogContent className={'min-w-[850px]'}>
                    <DialogHeader>
                        {dialogType === 'filter' ? (
                            <div className={'flex gap-2'}>
                                <span className={'text-2xl'}>Filters</span>
                                {filterCount > 0 && (
                                    <div
                                        className={
                                            'rounded-full bg-primary text-primary-foreground text-xs px-3 py-2'
                                        }
                                    >
                                        {filterCount}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div>Export</div>
                        )}
                    </DialogHeader>
                    {dialogType === 'filter' ? (
                        <ConsumptionFilters
                            filter={consumptionFilter}
                            onFilterChange={onFilterChange}
                            onClearFilters={resetActiveFilters}
                            onClose={() => setDialogOpen(false)}
                        />
                    ) : (
                        <ExportForm
                            queryParams={queryParams}
                            currentWindow={consumptionFilter.executionDate}
                            filterCount={filterCount}
                            totalCount={operationsQuery.data?.totalCount ?? 0}
                        />
                    )}
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default Consumption
