import { Button } from '@/components/ui/button.tsx'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.tsx'
import { MessagesSquare, X } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from '@/lib/i18n'
import { HelpCenterChat } from './helpCenterChat'

export const HelpCenter = () => {
    const { t } = useTranslation()

    const [dropDownOpen, setDropDownOpen] = useState(false)

    return (
        <DropdownMenu open={dropDownOpen} onOpenChange={setDropDownOpen}>
            <DropdownMenuTrigger asChild>
                <Button variant={'ghost'} size={'icon'}>
                    <MessagesSquare className={dropDownOpen ? 'text-primary' : ''} />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align={'end'} className={'w-[411px] bg-background p-0'}>
                <div className="flex justify-center gap-4 pt-4">
                    <Button
                        className="absolute top-2 left-2 text-black"
                        variant={'textOnly'}
                        onClick={() => setDropDownOpen(false)}
                    >
                        <X className="w-4"></X>
                    </Button>
                    <h1 className="font-bold text-sm">{t('help_center.title')}</h1>
                </div>
                <hr className="mt-2 mb-2" />
                <HelpCenterChat />
            </DropdownMenuContent>
        </DropdownMenu>
    )
}
