import {
    GetTenantResponseV1,
    createTenantV1,
    getTenantDetailsV1,
    getTenantV1,
} from '@/api/marketplace.ts'
import { toast } from '@/components/ui/use-toast.ts'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useTranslation } from '@/lib/i18n'

const useTenantQuery = () => {
    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const [tenant, setTenant] = useState<GetTenantResponseV1 | null>()

    const tenantQuery = useQuery({
        queryKey: [QueryKeys.TENANT],
        queryFn: getTenantV1,
        retry: false,
    })

    const tenantDetailsQuery = useQuery({
        queryKey: [QueryKeys.TENANT_DETAILS],
        queryFn: getTenantDetailsV1,
        enabled: !!tenantQuery.data, // only run when tenant exists
    })

    const createTenantMutation = useMutation({
        mutationFn: () => createTenantV1({ emailAddress: '' }),
        onSuccess: (data) => {
            if (data) {
                // Invalidate and refetch the tenant query after creation
                queryClient.invalidateQueries({ queryKey: [QueryKeys.TENANT] })
                queryClient.invalidateQueries({ queryKey: [QueryKeys.TENANT_DETAILS] })
            }
        },
        onError: () => {
            setTenant(undefined)
            toast({
                title: t('error_title'),
                description: t('error_description_generic'),
                variant: 'destructive',
            })
        },
    })

    useEffect(() => {
        if (!tenantQuery.isLoading) {
            if (tenantQuery.data) {
                setTenant(tenantQuery.data)
            } else {
                setTenant(null)
            }
        }
    }, [tenantQuery.data, tenantQuery.isLoading])

    useEffect(() => {
        // If tenant doesn't exist, and we're not already creating one, trigger creation
        if (tenant === null && !createTenantMutation.isPending) {
            createTenantMutation.mutate()
        }
    }, [createTenantMutation.isPending, tenant])

    return {
        tenantExists: !!tenant,
        tenant,
        details: tenantDetailsQuery.data,
        isLoading:
            tenantQuery.isLoading || createTenantMutation.isPending || tenantDetailsQuery.isLoading,
        isError: tenantQuery.isError || createTenantMutation.isError,
    }
}

export default useTenantQuery
